<template>
    <div class="border-grey relative grid gap-x-16 border-b py-20">
        <div class="flex-col items-center gap-x-12 md:flex md:flex-row">
            <div
                class="image-wrapper relative mx-auto w-3/4 hover:cursor-zoom-in md:w-1/3 md:flex-none"
                @click="zoomModal?.open()"
            >
                <StoryblokImage
                    v-if="data.product.image"
                    :src="data.product.image.src"
                    :alt="data.product.image.alt"
                    sizes="274px md:501px"
                    :image-width="501"
                    :image-height="501"
                    class="relative"
                    :preload="index === 0"
                />
                <div
                    class="invisible absolute bottom-0 right-0 h-[40px] w-[40px] rounded-md bg-grind pl-[10px] pt-[10px] text-lg text-white lg:visible"
                >
                    <Icon name="magnifying-glass" />
                </div>
            </div>
            <div class="flex flex-1 flex-col justify-center md:flex-initial">
                <h3 v-if="data.title" class="pb-4">
                    {{ data.title }}
                </h3>
                <p
                    v-if="data.subtitle"
                    class="pb-4 text-lg font-bold text-drip"
                >
                    {{ data.subtitle }}
                </p>
                <WysiwygContent
                    :content="data.product.description"
                    class="mb-4"
                />
            </div>
            <div class="flex justify-center md:flex-none">
                <ClientOnly>
                    <CheckboxInput
                        v-model="selected"
                        :name="slugify(data.title)"
                        label=""
                        :submitting="false"
                        class="large-checkbox"
                        container-class="items-center"
                        label-class="text-sm"
                        @update:model-value="$emit('toggle', $event)"
                    />
                </ClientOnly>
            </div>
        </div>

        <Modal
            ref="zoomModal"
            :show-action-buttons="false"
            width-class="w-full lg:w-1/2"
        >
            <StoryblokImage
                v-if="data.product.image"
                :src="data.product.image.src"
                :alt="data.product.image.alt"
                class="relative"
            />
        </Modal>
    </div>
</template>

<script setup lang="ts">
import SamplePackType from '~/types/SamplePackType';
import CheckboxInput from '~/components/forms/CheckboxInput.vue';
import Modal from '~/components/Modal.vue';
import { ref } from 'vue';

defineProps<{
    data: SamplePackType;
    index: number;
}>();

const zoomModal = ref<InstanceType<typeof Modal> | null>(null);

const state = reactive({
    selected: false,
});

const { selected } = toRefs(state);

defineEmits(['toggle']);
</script>

<style lang="postcss" scoped>
:deep(input[type='checkbox'].large-checkbox) {
    @apply h-9 w-9 !important;
}
</style>
